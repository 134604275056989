import {DownloadOutlined, EyeOutlined} from '@ant-design/icons';
import {Button, Col, Row, Space, Spin} from 'antd';
import prettyBytes from 'pretty-bytes';
import {FC, useCallback, useContext} from 'react';
import {useAsync} from '../hooks/useAsync';
import {fetchApi, newApiUrl} from '../util';
import {SessionContext} from './Session';

interface FileTableProps {
  folder: string;
}

const {REACT_APP_OFFLINE_MODE} = process.env;

interface FileProps {
  name: string;
  thumbnailName?: string;
  size: number;
  url?: string;
  thumbnailUrl?: string;
  mimeType: string;
}

const offlineModeFiles: {[key: string]: FileProps[]} = {
  brochures: [
    'BE1221 VIBIO 2020',
    'earback',
    'hygiène interson',
    'pdf maxi-pro',
    'pianissimo',
    'visit 2020',
  ].map((name, index) => ({
    name: `Brochure ${name}`,
    thumbnailUrl: `/assets/2022/demo/brochures/${index + 1}.png`,
    size: 12121230,
    url: '/assets/cgv.pdf',
    mimeType: 'application/pdf',
  })),
  videos: Array(4)
    .fill(null)
    .map(() => ({
      name: 'Big Buck Bunny',
      size: 158008374,
      url:
        'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
      thumbnailUrl:
        'https://storage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg',
      mimeType: 'video/mp4',
    })),
};

const FileTable: FC<FileTableProps> = ({folder}) => {
  const [session] = useContext(SessionContext);

  const req = useCallback(async () => {
    if (REACT_APP_OFFLINE_MODE)
      return offlineModeFiles[folder as keyof typeof offlineModeFiles] ?? [];
    const url = newApiUrl(`/files/list/${folder}`);
    const res = await fetchApi({session, url});
    return (await res.json()) as FileProps[];
  }, [session, folder]);
  const {value} = useAsync(req);

  const getFileUrl = (name: string): URL => {
    if (
      name.startsWith('https://') ||
      name.startsWith('http://') ||
      name.startsWith('/')
    ) {
      return new URL(
        name.startsWith('/') ? [window.location.origin, name].join('') : name,
      );
    }
    return newApiUrl(`/file/${encodeURIComponent([folder, name].join('/'))}`);
  };

  const downloadFile = async (name: string, fileName?: string) => {
    const url = getFileUrl(name);

    const res = await fetchApi({session, url});
    if (!res.status.toString().startsWith('2')) {
      return;
    }

    const objectUrl = window.URL.createObjectURL(await res.blob());
    {
      const $anchor = document.createElement('a');
      $anchor.href = objectUrl;
      $anchor.download = fileName ?? name;
      document.body.appendChild($anchor);
      $anchor.click();
      $anchor.remove();
    }
    window.URL.revokeObjectURL(objectUrl);
  };

  if (!value) {
    return (
      <Space size="middle">
        <Spin size="large" />
      </Space>
    );
  }

  if (!value.length) {
    return <p>Aucun fichier trouvé</p>;
  }

  return (
    <Row gutter={15}>
      {value.map((file, index) => (
        <Col
          span={file.mimeType?.startsWith('video/') ? 8 : 6}
          key={index}
          className="document-card"
        >
          <div className="document-container">
            {file.mimeType?.startsWith('video/') ? (
              <video
                src={getFileUrl(file.name).toString()}
                controls
                poster={
                  file.thumbnailName
                    ? getFileUrl(file.thumbnailName).toString()
                    : ''
                }
              >
                <source
                  src={getFileUrl(file.name).toString()}
                  type={file.mimeType}
                />
              </video>
            ) : (
              <>
                <div className="overlay">
                  <a
                    href={getFileUrl(file.name).toString()}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      icon={<EyeOutlined />}
                      shape="circle"
                      title="Visionner"
                    />
                  </a>
                  <Button
                    onClick={() =>
                      downloadFile(file.url ?? file.name, file.name)
                    }
                    icon={<DownloadOutlined />}
                    shape="circle"
                    title="Télécharger"
                  />
                </div>
                <img
                  src={
                    file.thumbnailUrl ??
                    (file.thumbnailName
                      ? getFileUrl(file.thumbnailName).toString()
                      : '')
                  }
                  alt={`Aperçu ${file.name}`}
                />
              </>
            )}
          </div>
          <div>
            <a
              href={getFileUrl(file.name).toString()}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button type="link" size="small">
                {file.name}
              </Button>
            </a>
          </div>
          <div className="document-size">{prettyBytes(file.size)}</div>
        </Col>
      ))}
    </Row>
  );
};

export default FileTable;
