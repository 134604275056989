import {Button, Card, Col, Row} from 'antd';
import React, {FC} from 'react';
import Pitch from './Home/Pitch';

const contactInfos = [
  {
    town: 'Nîmes',
    imageKey: 'nimes',
    company: 'INTERSON PROTAC',
    address: "1, route d'Aubais - 30111 Congénies ",
    phoneNumbers: [
      {label: 'Accueil / Commande / SAV', value: '04 66 80 22 89'},
      {label: 'Comptabilité', value: '04 66 80 67 90'},
      {label: 'Fax', value: '04 66 80 22 81 '},
    ],
    email: 'nimes@interson-protac.com',
  },
  {
    town: 'Paris',
    imageKey: 'paris',
    company: 'SURDIFUSE',
    address: '19, rue des Petites Ecuries - 75010 Paris ',
    phoneNumbers: [
      {label: 'Accueil / Commande / SAV', value: '01 47 70 97 11'},
      {label: 'Fax', value: '01 47 70 97 12 '},
      {label: '', value: ''},
    ],
    email: 'paris@interson-protac.com',
  },
  {
    town: 'Lyon',
    imageKey: 'lyon',
    company: "L'EMBOUT FRANCAIS",
    address: '2, impasse Maurice Ravel - 69330 Jonage ',
    phoneNumbers: [
      {label: 'Accueil / Commande / SAV', value: '04 72 37 12 70'},
      {label: 'Fax', value: '04 78 26 01 02 '},
      {label: '', value: ''},
    ],
    email: 'lyon@interson-protac.com',
  },
];

const Contact: FC = () => {
  return (
    <>
      <Pitch background="Contact" foreground="Contacter un service interne" />

      <Row gutter={15}>
        {contactInfos.map(
          ({town, imageKey, address, email, phoneNumbers}, index) => (
            <Col key={index} span={24} lg={8} className="contact-card">
              <Card
                title={
                  <div className="contact-title">
                    <div className="background">
                      <img
                        alt={town}
                        src={`/assets/2022/embouts-${imageKey}.jpg`}
                      />
                    </div>
                    <div className="foreground">
                      <img
                        alt={`Logo ${town}`}
                        src={`/assets/2022/${imageKey}.svg`}
                      />
                      <span>{town}</span>
                    </div>
                  </div>
                }
                style={{
                  height: '420px',
                  boxShadow: '5px 5px 15px lightgrey',
                  border: 'none',
                }}
              >
                <div style={{textAlign: 'left', color: '#78787d'}}>
                  <p
                    style={{
                      textAlign: 'center',
                      whiteSpace: 'pre',
                      fontWeight: 'bold',
                    }}
                  >
                    {address.split(' - ').join('\n')}
                  </p>
                  {phoneNumbers.map(({label, value}, index) => (
                    <p key={index}>
                      {label ? (
                        <>
                          {label} :<br />
                          <a
                            href={`tel:${value}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <b>{value}</b>
                          </a>
                        </>
                      ) : null}
                    </p>
                  ))}
                  <p>
                    E-mail :<br />
                    <a
                      href={`mailto:${email}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <b>{email}</b>
                    </a>
                  </p>
                </div>
              </Card>
              <div
                style={{
                  textAlign: 'center',
                  position: 'relative',
                  top: '-15px',
                }}
              >
                <Button
                  href={`mailto:${email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  type="primary"
                  style={{textTransform: 'uppercase'}}
                >
                  J'envoie un e-mail !
                </Button>
              </div>
            </Col>
          ),
        )}
      </Row>
    </>
  );
};

export default Contact;
