import {DeleteOutlined, EyeOutlined, SearchOutlined} from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import {ColumnsType, ColumnType, TableProps} from 'antd/lib/table';
import React, {
  FC,
  ReactText,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {OrderList} from '../../api';
import {useAsync} from '../../hooks/useAsync';
import {fetchApi, newApiUrl, openOrderUrl} from '../../util';
import Pitch from '../Home/Pitch';
import {SessionContext} from '../Session';

const {Title} = Typography;
const {RangePicker} = DatePicker;
const {Option} = Select;
const {Panel} = Collapse;

const statusLabels = [
  'En attente',
  'En cours',
  "Prêt à l'expédition",
  'Commande expédiée',
  'Fabrication annulée',
  'Accessoires livrés partiellement',
];

const statusColors = [
  'black',
  '#2828ff',
  '#bb354d',
  'green',
  'gray',
  '#a06800',
];

const siteIcons = {
  Paris: '/assets/2022/paris.svg',
  Nîmes: '/assets/2022/nimes.svg',
  Lyon: '/assets/2022/lyon.svg',
};

const {REACT_APP_OFFLINE_MODE} = process.env;

const offlineModeOrders = [
  {
    ID: 2,
    site: 'Nîmes',
    date: '01/05/2022, 01:08',
    date_ts: 1651360111180,
    shippingDate: '22/05/2022, 23:15',
    shippingDate_ts: 1652390111180,
    orderId: 'NW12312311',
    patientName: 'Jeanne Dulac',
    product: 'Aquastop',
    ears: 'OG',
    status: 3,
    trackingId: '12345',
  },
  {
    ID: 3,
    site: 'Accessoire',
    date: '01/05/2022, 01:08',
    date_ts: 1651360111180,
    orderId: 'NW12312311',
    patientName: 'Patient',
    product: 'Aquastop',
    ears: 'OG',
    status: 0,
    trackingId: '',
  },
  {
    ID: 1,
    site: 'Paris',
    date: '06/05/2022, 10:13',
    date_ts: 1651824800023,
    shippingDate: '',
    shippingDate_ts: 0,
    orderId: 'PA123123123',
    patientName: 'Jean Dupont',
    product: 'Aquastop',
    ears: 'OG+OD',
    status: 1,
    trackingId: '',
  },
];

const layout = {};
interface OrderFilterFormValues {
  dateRange: string;
  status: string;
  patientName: boolean;
}

const Orders: FC = () => {
  const [session] = useContext(SessionContext);

  const [, setSearchText] = useState('');
  const [, setSearchedColumn] = useState('');
  const [loading, setLoading] = useState(true);
  const [filteredInfo, setFilteredInfo] = useState<
    {[key in keyof OrderList]?: ReactText[] | null}
  >({});
  const [removedOrders, setRemovedOrders] = useState<string[]>([]);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(form.getFieldsValue());

  const searchFiltersClears: (() => void)[] = [];
  const onFiltersReset = () => {
    form?.resetFields();
    setFormValues({});
    setFilteredInfo({});
    searchFiltersClears.map((fn) => fn?.());
  };

  const handleReset = (clearFilters?: Function) => {
    clearFilters?.();
    setSearchText('');
  };
  const handleSearch = (
    dataIndex: keyof OrderList,
    selectedKeys: string[],
    confirm: Function,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(selectedKeys[0]);
    // setFilteredInfo({...filteredInfo, [dataIndex]: selectedKeys[0]});
  };
  const handleChange: TableProps<any>['onChange'] = (_, filters) => {
    setFilteredInfo(filters);
  };

  const getColumnSearchProps = (
    dataIndex: keyof OrderList,
  ): Partial<ColumnType<OrderList>> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{padding: 8}}>
        <Input
          placeholder={`Recherche par ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(dataIndex, selectedKeys as string[], confirm)
          }
          style={{width: 188, marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(dataIndex, selectedKeys as string[], confirm)
            }
            size="small"
            style={{width: 90}}
          >
            Recherche
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{width: 90}}
          >
            Réinit.
          </Button>
        </Space>
      </div>
    ),
    // @ts-ignore
    filteredValue: filteredInfo[dataIndex] || null,
    filterIcon: (filtered) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value: string | number | boolean, record: OrderList) =>
      // @ts-ignore
      record[dataIndex]
        ? // @ts-ignore
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : false,
  });

  const req = useCallback(async () => {
    if (REACT_APP_OFFLINE_MODE) return {items: offlineModeOrders};
    const url = newApiUrl('/orders');
    const res = await fetchApi({session, url});
    setLoading(false);
    return await res.json();
  }, [session]);
  const {value} = useAsync(req);

  const columns: ColumnsType<OrderList> = [
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <>
          {['Nîmes', 'Paris'].includes(record.site) ? (
            <Space size="middle">
              <Button
                className="primary-inverted"
                icon={<EyeOutlined />}
                shape="circle"
                title="Détail de ma commande"
                onClick={() =>
                  session &&
                  record.ID &&
                  openOrderUrl(
                    record.ID,
                    record.site === 'Paris' ? 'surdifuse' : 'intersonProtac',
                    session,
                  )
                }
              />
            </Space>
          ) : null}
        </>
      ),
    },
    {
      title: 'Site',
      dataIndex: 'site',
      key: 'site',
      render: (site: keyof typeof siteIcons) =>
        siteIcons[site] ? (
          <img
            src={siteIcons[site]}
            title={site}
            alt={`Logo ${site}`}
            style={{filter: 'invert()', maxWidth: '30px'}}
          />
        ) : (
          site
        ),
      sorter: (a, b) => a.site.localeCompare(b.site),
      filteredValue: filteredInfo.site || null,
      onFilter: (value, record) => record.site === value,
      filters: ['Nîmes', 'Paris', 'Lyon', 'Accessoire'].map((site) => ({
        text: site,
        value: site,
      })),
    },
    {
      title: 'Commande',
      dataIndex: 'orderId',
      key: 'orderId',
      sorter: (a, b) => (a.orderId || '').localeCompare(b.orderId || ''),
      ...getColumnSearchProps('orderId'),
    },
    {
      title: 'Patient',
      dataIndex: 'patientName',
      key: 'patientName',
      sorter: (a, b) =>
        (a.patientName || '').localeCompare(b.patientName || ''),
      ...getColumnSearchProps('patientName'),
    },
    {
      title: 'Date de saisie',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => (a.date_ts || 0) - (b.date_ts || 0),
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span style={{color: statusColors[status]}}>
          {statusLabels[status]}
        </span>
      ),
      sorter: (a, b) => (a.status || 0) - (b.status || 0),
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status === value,
      filters: statusLabels.map((status, index) => ({
        text: <span style={{color: statusColors[index]}}>{status}</span>,
        value: index,
      })),
    },
    {
      title: 'Date de départ',
      dataIndex: 'shippingDate',
      key: 'shippingDate',
      sorter: (a, b) => (a.shippingDate_ts || 0) - (b.shippingDate_ts || 0),
    },
    {
      title: 'Suivi',
      dataIndex: 'trackingId',
      key: 'trackingId',
      sorter: (a, b) => (a.trackingId || '').localeCompare(b.trackingId || ''),
      ...getColumnSearchProps('trackingId'),
    },
    {
      title: '',
      key: 'action2',
      render: (_, record) => (
        <>
          {['Nîmes', 'Paris', 'Accessoire'].includes(record.site) &&
          record.status === 0 ? (
            <Space size="middle">
              <Popconfirm
                placement="top"
                title="Voulez-vous vraiment supprimer cette commande ?"
                onConfirm={async () => {
                  if (!session || !record.ID) return;

                  const site =
                    record.site === 'Nîmes'
                      ? 'intersonProtac'
                      : record.site === 'Paris'
                      ? 'surdifuse'
                      : 'accessoire';

                  const url = newApiUrl(`/order/${site}/${record.ID}`);

                  setLoading(true);
                  try {
                    await fetchApi({session, url, method: 'DELETE'});
                    setRemovedOrders([...removedOrders, record.ID]);
                  } finally {
                    setLoading(false);
                  }
                }}
                okButtonProps={{disabled: loading}}
                okText="Supprimer"
                cancelText="Annuler"
              >
                <Button
                  type="primary"
                  icon={<DeleteOutlined />}
                  shape="circle"
                />
              </Popconfirm>
            </Space>
          ) : null}
        </>
      ),
    },
  ];

  const initialValues = value?.items;
  const filteredValues = useMemo(() => {
    let filteredValues = initialValues ?? [];

    if (formValues.dateRange?.length) {
      const [start, end] = [
        formValues.dateRange[0].startOf('day').unix() * 1000,
        formValues.dateRange[1].endOf('day').unix() * 1000,
      ];
      console.log(formValues.dateRange);
      console.log({start, end});
      filteredValues = filteredValues.filter(
        ({date_ts}: {date_ts: number}) => date_ts >= start && date_ts <= end,
      );
    }

    if (typeof formValues.status !== 'undefined') {
      filteredValues = filteredValues.filter(
        ({status}: {status: number}) => status === formValues.status,
      );
    }

    if (formValues.patientName?.length) {
      filteredValues = filteredValues.filter(
        ({patientName}: {patientName: string}) =>
          patientName
            .toLocaleLowerCase()
            .includes(formValues.patientName.toLocaleLowerCase()),
      );
    }

    if (removedOrders.length) {
      filteredValues = filteredValues.filter(
        ({ID}: {ID: string}) => !removedOrders.includes(ID),
      );
    }

    return filteredValues;
  }, [initialValues, removedOrders, formValues]);

  const activeFilters = useMemo(() => {
    let activeFilters = 0;

    if (formValues.dateRange?.length) ++activeFilters;
    if (typeof formValues.status !== 'undefined') ++activeFilters;
    if (formValues.patientName?.length) ++activeFilters;

    return activeFilters;
  }, [formValues]);

  if (!initialValues?.length) {
    return (
      <div>
        <Pitch background="Mon espace" foreground="Suivi de commandes" />
        <p>Aucune commande</p>
      </div>
    );
  }

  return (
    <div style={{minWidth: '1200px', marginBottom: '30px'}}>
      <Pitch background="Mon espace" foreground="Suivi de commandes" />

      <Collapse defaultActiveKey="1" className="orders-collapse">
        <Panel
          key="1"
          header={
            <Row style={{justifyContent: 'space-between'}}>
              <Col
                style={{
                  fontSize: '20px',
                  color: '#78787d',
                }}
              >
                Filtres{' '}
                {activeFilters ? (
                  <Badge
                    count={activeFilters}
                    style={{backgroundColor: '#dd3214'}}
                  />
                ) : null}
              </Col>
              <Col>
                <Button
                  className="primary-inverted"
                  onClick={() => onFiltersReset()}
                  style={{
                    float: 'right',
                    position: 'relative',
                    top: '2px',
                    textTransform: 'uppercase',
                  }}
                >
                  Réinitialiser les filtres
                </Button>
              </Col>
            </Row>
          }
        >
          <Form<OrderFilterFormValues>
            {...layout}
            layout="inline"
            name="basic"
            className="filter-form"
            form={form}
            onFinish={() => setFormValues(form.getFieldsValue())}
          >
            <Form.Item label="Date de commande" name="dateRange">
              <RangePicker allowClear />
            </Form.Item>
            <Form.Item label="État" name="status">
              <Select
                style={{minWidth: '150px'}}
                placeholder="Choisir l'état"
                allowClear
              >
                {statusLabels.map((label, status) => (
                  <Option
                    key={status}
                    value={status}
                    style={{color: statusColors[status]}}
                  >
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Nom du patient" name="patientName">
              <Input allowClear />
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              style={{textTransform: 'uppercase'}}
            >
              Filtrer
            </Button>
          </Form>
        </Panel>
      </Collapse>

      <Title
        level={3}
        style={{
          marginTop: '25px',
          textAlign: 'left',
          textTransform: 'uppercase',
          color: '#78787d',
        }}
      >
        Commande{initialValues.length > 1 ? 's' : ''} ({initialValues.length})
      </Title>

      <Table
        className="orders-table"
        dataSource={filteredValues}
        columns={columns}
        onChange={handleChange}
      />
    </div>
  );
};

export default Orders;
