/* Generated by restful-react */

import React from "react";
import { Get, GetProps, useGet, UseGetProps, Mutate, MutateProps, useMutate, UseMutateProps } from "restful-react";
export interface ApiError {
  /**
   * A user readable-error message
   */
  message: string;
  /**
   * The error stack. Will not be provided in production
   */
  stack?: string;
}

export interface ColumnSort {
  /**
   * The column name
   */
  name: string;
  direction: ColumnSortDirection;
}

export type ColumnSortDirection = "ASC" | "DESC";

export interface UserList {
  /**
   * Internal user identifier
   */
  id: number;
  /**
   * UUID user identifier
   */
  uuid: string;
  /**
   * Unique user log-in identifier
   */
  identifiant: string;
  /**
   * User email address
   */
  email: string;
  /**
   * Hashed user password
   */
  password?: string;
  /**
   * User code
   */
  code: string;
  /**
   * User enterprise name
   */
  raisonSociale: string;
  /**
   * Interson Protac UUID user identifier
   */
  id_intersonProtac: string;
  /**
   * Embout Français UUID user identifier
   */
  id_emboutFrancais: string;
  /**
   * Surdifuse UUID user identifier
   */
  id_surdifuse: string;
  /**
   * Négoce UUID user identifier
   */
  id_accessoire: string;
}

export type UsersList = {
  /**
   * Internal user identifier
   */
  id: number;
  /**
   * UUID user identifier
   */
  uuid: string;
  /**
   * Unique user log-in identifier
   */
  identifiant: string;
  /**
   * User email address
   */
  email: string;
  /**
   * Hashed user password
   */
  password?: string;
  /**
   * User code
   */
  code: string;
  /**
   * User enterprise name
   */
  raisonSociale: string;
  /**
   * Interson Protac UUID user identifier
   */
  id_intersonProtac: string;
  /**
   * Embout Français UUID user identifier
   */
  id_emboutFrancais: string;
  /**
   * Surdifuse UUID user identifier
   */
  id_surdifuse: string;
  /**
   * Négoce UUID user identifier
   */
  id_accessoire: string;
}[];

export type UsersPost = {
  /**
   * Internal user identifier
   */
  id?: number;
  /**
   * UUID user identifier
   */
  uuid: string;
  /**
   * Unique user log-in identifier
   */
  identifiant: string;
  /**
   * User email address
   */
  email: string;
  /**
   * Hashed user password
   */
  password: string;
  /**
   * User code
   */
  code: string;
  /**
   * User enterprise name
   */
  raisonSociale: string;
  /**
   * Interson Protac UUID user identifier
   */
  id_intersonProtac?: string;
  /**
   * Embout Français UUID user identifier
   */
  id_emboutFrancais?: string;
  /**
   * Surdifuse UUID user identifier
   */
  id_surdifuse?: string;
  /**
   * Négoce UUID user identifier
   */
  id_accessoire?: string;
}[];

export interface FileList {
  /**
   * The file name
   */
  name: string;
  /**
   * The file size, in byes
   */
  size: number;
}

export type FilesList = {
  /**
   * The file name
   */
  name: string;
  /**
   * The file size, in byes
   */
  size: number;
}[];

export interface OrderList {
  ID?: string;
  site: string;
  date?: string;
  shippingDate?: string;
  date_ts?: number;
  shippingDate_ts?: number;
  orderId?: string;
  patientName?: string;
  product?: string;
  ears?: string;
  status?: number;
  trackingId?: string;
}

export type OrdersList = {
  ID?: string;
  site: string;
  date?: string;
  shippingDate?: string;
  date_ts?: number;
  shippingDate_ts?: number;
  orderId?: string;
  patientName?: string;
  product?: string;
  ears?: string;
  status?: number;
  trackingId?: string;
}[];

export interface GetHealthResponse {
  status: string;
}

export type GetHealthProps = Omit<GetProps<GetHealthResponse, unknown, void, void>, "path">;

/**
 * Retrieve the health of the API
 */
export const GetHealth = (props: GetHealthProps) => (
  <Get<GetHealthResponse, unknown, void, void>
    path={`/health`}
    
    {...props}
  />
);

export type UseGetHealthProps = Omit<UseGetProps<GetHealthResponse, unknown, void, void>, "path">;

/**
 * Retrieve the health of the API
 */
export const useGetHealth = (props: UseGetHealthProps) => useGet<GetHealthResponse, unknown, void, void>(`/health`, props);


export type DeleteUserProps = Omit<MutateProps<number, ApiError, void, string, void>, "path" | "verb">;

/**
 * Deletes a single user
 */
export const DeleteUser = (props: DeleteUserProps) => (
  <Mutate<number, ApiError, void, string, void>
    verb="DELETE"
    path={`/user`}
    
    {...props}
  />
);

export type UseDeleteUserProps = Omit<UseMutateProps<number, ApiError, void, string, void>, "path" | "verb">;

/**
 * Deletes a single user
 */
export const useDeleteUser = (props: UseDeleteUserProps) => useMutate<number, ApiError, void, string, void>("DELETE", `/user`, {   ...props });


export interface ResetUserPasswordRequestBody {
  /**
   * The user login
   */
  login: string;
}

export type ResetUserPasswordProps = Omit<MutateProps<void, unknown, void, ResetUserPasswordRequestBody, void>, "path" | "verb">;

/**
 * Reset a user password
 */
export const ResetUserPassword = (props: ResetUserPasswordProps) => (
  <Mutate<void, unknown, void, ResetUserPasswordRequestBody, void>
    verb="POST"
    path={`/user/resetPassword`}
    
    {...props}
  />
);

export type UseResetUserPasswordProps = Omit<UseMutateProps<void, unknown, void, ResetUserPasswordRequestBody, void>, "path" | "verb">;

/**
 * Reset a user password
 */
export const useResetUserPassword = (props: UseResetUserPasswordProps) => useMutate<void, unknown, void, ResetUserPasswordRequestBody, void>("POST", `/user/resetPassword`, props);


export interface SetUserPasswordRequestBody {
  /**
   * The orevious user password
   */
  oldPassword: string;
  /**
   * The new user password
   */
  newPassword: string;
}

export type SetUserPasswordProps = Omit<MutateProps<void, unknown, void, SetUserPasswordRequestBody, void>, "path" | "verb">;

/**
 * Set a user password
 */
export const SetUserPassword = (props: SetUserPasswordProps) => (
  <Mutate<void, unknown, void, SetUserPasswordRequestBody, void>
    verb="POST"
    path={`/user/setPassword`}
    
    {...props}
  />
);

export type UseSetUserPasswordProps = Omit<UseMutateProps<void, unknown, void, SetUserPasswordRequestBody, void>, "path" | "verb">;

/**
 * Set a user password
 */
export const useSetUserPassword = (props: UseSetUserPasswordProps) => useMutate<void, unknown, void, SetUserPasswordRequestBody, void>("POST", `/user/setPassword`, props);


export interface GetUsersResponse {
  /**
   * The total number of items in DB
   */
  total?: number;
  items?: UsersList;
}

export interface GetUsersQueryParams {
  id?: string[];
  uuid?: string[];
  code?: string[];
  /**
   * The maximum number of results to return. Set to -1 for no limit.
   */
  limit?: number;
  /**
   * The offset of results
   */
  offset?: number;
  /**
   * The field(s) used to sort the results
   */
  sort?: ColumnSort[];
}

export type GetUsersProps = Omit<GetProps<GetUsersResponse, unknown, GetUsersQueryParams, void>, "path">;

/**
 * Retrieve users
 */
export const GetUsers = (props: GetUsersProps) => (
  <Get<GetUsersResponse, unknown, GetUsersQueryParams, void>
    path={`/users`}
    
    {...props}
  />
);

export type UseGetUsersProps = Omit<UseGetProps<GetUsersResponse, unknown, GetUsersQueryParams, void>, "path">;

/**
 * Retrieve users
 */
export const useGetUsers = (props: UseGetUsersProps) => useGet<GetUsersResponse, unknown, GetUsersQueryParams, void>(`/users`, props);


export type PostUsersProps = Omit<MutateProps<number[], unknown, void, UsersPost, void>, "path" | "verb">;

/**
 * Save users for other factories
 */
export const PostUsers = (props: PostUsersProps) => (
  <Mutate<number[], unknown, void, UsersPost, void>
    verb="POST"
    path={`/users`}
    
    {...props}
  />
);

export type UsePostUsersProps = Omit<UseMutateProps<number[], unknown, void, UsersPost, void>, "path" | "verb">;

/**
 * Save users for other factories
 */
export const usePostUsers = (props: UsePostUsersProps) => useMutate<number[], unknown, void, UsersPost, void>("POST", `/users`, props);


export type DeleteAllUsersProps = Omit<MutateProps<number, unknown, void, void, void>, "path" | "verb">;

/**
 * Deletes all database users
 */
export const DeleteAllUsers = (props: DeleteAllUsersProps) => (
  <Mutate<number, unknown, void, void, void>
    verb="DELETE"
    path={`/users/all`}
    
    {...props}
  />
);

export type UseDeleteAllUsersProps = Omit<UseMutateProps<number, unknown, void, void, void>, "path" | "verb">;

/**
 * Deletes all database users
 */
export const useDeleteAllUsers = (props: UseDeleteAllUsersProps) => useMutate<number, unknown, void, void, void>("DELETE", `/users/all`, {   ...props });


export interface GetSessionResponse {
  /**
   * The session token
   */
  token: string;
  /**
   * ISO 8601 string of when the session will be invalid
   */
  expires: string;
}

export type GetSessionProps = Omit<GetProps<GetSessionResponse, ApiError, void, void>, "path">;

/**
 * Get current session
 */
export const GetSession = (props: GetSessionProps) => (
  <Get<GetSessionResponse, ApiError, void, void>
    path={`/session`}
    
    {...props}
  />
);

export type UseGetSessionProps = Omit<UseGetProps<GetSessionResponse, ApiError, void, void>, "path">;

/**
 * Get current session
 */
export const useGetSession = (props: UseGetSessionProps) => useGet<GetSessionResponse, ApiError, void, void>(`/session`, props);


export interface PutSessionResponse {
  /**
   * The session token
   */
  token: string;
  /**
   * ISO 8601 string of when the session will be invalid
   */
  expires: string;
}

export interface PutSessionRequestBody {
  /**
   * The user login, either their userName or email
   */
  login: string;
  /**
   * The user password
   */
  password: string;
}

export type PutSessionProps = Omit<MutateProps<PutSessionResponse, ApiError, void, PutSessionRequestBody, void>, "path" | "verb">;

/**
 * Request a new session (log in)
 */
export const PutSession = (props: PutSessionProps) => (
  <Mutate<PutSessionResponse, ApiError, void, PutSessionRequestBody, void>
    verb="PUT"
    path={`/session`}
    
    {...props}
  />
);

export type UsePutSessionProps = Omit<UseMutateProps<PutSessionResponse, ApiError, void, PutSessionRequestBody, void>, "path" | "verb">;

/**
 * Request a new session (log in)
 */
export const usePutSession = (props: UsePutSessionProps) => useMutate<PutSessionResponse, ApiError, void, PutSessionRequestBody, void>("PUT", `/session`, props);


export type DeleteSessionProps = Omit<MutateProps<void, ApiError, void, string, void>, "path" | "verb">;

/**
 * Revoke a user session (log out)
 */
export const DeleteSession = (props: DeleteSessionProps) => (
  <Mutate<void, ApiError, void, string, void>
    verb="DELETE"
    path={`/session`}
    
    {...props}
  />
);

export type UseDeleteSessionProps = Omit<UseMutateProps<void, ApiError, void, string, void>, "path" | "verb">;

/**
 * Revoke a user session (log out)
 */
export const useDeleteSession = (props: UseDeleteSessionProps) => useMutate<void, ApiError, void, string, void>("DELETE", `/session`, {   ...props });


export type GetUserFromSessionProps = Omit<GetProps<UserList, unknown, void, void>, "path">;

/**
 * Returns the user associated to the header's token
 */
export const GetUserFromSession = (props: GetUserFromSessionProps) => (
  <Get<UserList, unknown, void, void>
    path={`/session/getUser`}
    
    {...props}
  />
);

export type UseGetUserFromSessionProps = Omit<UseGetProps<UserList, unknown, void, void>, "path">;

/**
 * Returns the user associated to the header's token
 */
export const useGetUserFromSession = (props: UseGetUserFromSessionProps) => useGet<UserList, unknown, void, void>(`/session/getUser`, props);


export interface GetLoginTokenForSubResponse {
  /**
   * The session token
   */
  token: string;
  /**
   * The full sub site URL
   */
  url: string;
}

export interface GetLoginTokenForSubPathParams {
  /**
   * The id type to be generate
   */
  type: "intersonProtac" | "emboutFrancais" | "surdifuse" | "accessoire"
}

export type GetLoginTokenForSubProps = Omit<GetProps<GetLoginTokenForSubResponse, unknown, void, GetLoginTokenForSubPathParams>, "path"> & GetLoginTokenForSubPathParams;

/**
 * Retrieve a login token for a sub site
 */
export const GetLoginTokenForSub = ({type, ...props}: GetLoginTokenForSubProps) => (
  <Get<GetLoginTokenForSubResponse, unknown, void, GetLoginTokenForSubPathParams>
    path={`/session/getLoginToken/${type}`}
    
    {...props}
  />
);

export type UseGetLoginTokenForSubProps = Omit<UseGetProps<GetLoginTokenForSubResponse, unknown, void, GetLoginTokenForSubPathParams>, "path"> & GetLoginTokenForSubPathParams;

/**
 * Retrieve a login token for a sub site
 */
export const useGetLoginTokenForSub = ({type, ...props}: UseGetLoginTokenForSubProps) => useGet<GetLoginTokenForSubResponse, unknown, void, GetLoginTokenForSubPathParams>((paramsInPath: GetLoginTokenForSubPathParams) => `/session/getLoginToken/${paramsInPath.type}`, {  pathParams: { type }, ...props });


export interface GetFilePathParams {
  /**
   * The URI component encoded file path to download
   */
  filePath: string
}

export type GetFileProps = Omit<GetProps<void, ApiError, void, GetFilePathParams>, "path"> & GetFilePathParams;

/**
 * Download a file
 */
export const GetFile = ({filePath, ...props}: GetFileProps) => (
  <Get<void, ApiError, void, GetFilePathParams>
    path={`/file/${filePath}`}
    
    {...props}
  />
);

export type UseGetFileProps = Omit<UseGetProps<void, ApiError, void, GetFilePathParams>, "path"> & GetFilePathParams;

/**
 * Download a file
 */
export const useGetFile = ({filePath, ...props}: UseGetFileProps) => useGet<void, ApiError, void, GetFilePathParams>((paramsInPath: GetFilePathParams) => `/file/${paramsInPath.filePath}`, {  pathParams: { filePath }, ...props });


export interface ListFilesPathParams {
  /**
   * The URI component encoded folder path to list
   */
  folder: string
}

export type ListFilesProps = Omit<GetProps<FilesList, ApiError, void, ListFilesPathParams>, "path"> & ListFilesPathParams;

/**
 * List files
 */
export const ListFiles = ({folder, ...props}: ListFilesProps) => (
  <Get<FilesList, ApiError, void, ListFilesPathParams>
    path={`/files/list/${folder}`}
    
    {...props}
  />
);

export type UseListFilesProps = Omit<UseGetProps<FilesList, ApiError, void, ListFilesPathParams>, "path"> & ListFilesPathParams;

/**
 * List files
 */
export const useListFiles = ({folder, ...props}: UseListFilesProps) => useGet<FilesList, ApiError, void, ListFilesPathParams>((paramsInPath: ListFilesPathParams) => `/files/list/${paramsInPath.folder}`, {  pathParams: { folder }, ...props });


export interface DeleteOrderPathParams {
  /**
   * The site id of the order
   */
  site: string
}

export type DeleteOrderProps = Omit<MutateProps<void, unknown, void, string, DeleteOrderPathParams>, "path" | "verb"> & DeleteOrderPathParams;

/**
 * Delete order
 */
export const DeleteOrder = ({site, ...props}: DeleteOrderProps) => (
  <Mutate<void, unknown, void, string, DeleteOrderPathParams>
    verb="DELETE"
    path={`/order/${site}`}
    
    {...props}
  />
);

export type UseDeleteOrderProps = Omit<UseMutateProps<void, unknown, void, string, DeleteOrderPathParams>, "path" | "verb"> & DeleteOrderPathParams;

/**
 * Delete order
 */
export const useDeleteOrder = ({site, ...props}: UseDeleteOrderProps) => useMutate<void, unknown, void, string, DeleteOrderPathParams>("DELETE", (paramsInPath: DeleteOrderPathParams) => `/order/${paramsInPath.site}`, {  pathParams: { site }, ...props });


export interface GetOrdersResponse {
  /**
   * The total number of items in DB
   */
  total?: number;
  items?: OrdersList;
}

export type GetOrdersProps = Omit<GetProps<GetOrdersResponse, unknown, void, void>, "path">;

/**
 * Retrieve orders
 */
export const GetOrders = (props: GetOrdersProps) => (
  <Get<GetOrdersResponse, unknown, void, void>
    path={`/orders`}
    
    {...props}
  />
);

export type UseGetOrdersProps = Omit<UseGetProps<GetOrdersResponse, unknown, void, void>, "path">;

/**
 * Retrieve orders
 */
export const useGetOrders = (props: UseGetOrdersProps) => useGet<GetOrdersResponse, unknown, void, void>(`/orders`, props);

